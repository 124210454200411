enum AgencyFieldLabelEnum {
    firstname = 'Prénom',
    lastname = 'Nom',
    email = 'Email',
    phone = 'Téléphone',
    'photo.publicPath' = 'Photo',
    profession = 'Métier',
    comment = 'Commentaire',
}

export default AgencyFieldLabelEnum;
